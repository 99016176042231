import Actions from "../constants";
import { getKeycloakToken } from "../../lib/util";

export const uploadFile = (data, formFieldName, dialogueGraphId) => ({
  type: Actions.UPLOAD_FILE,
  token: getKeycloakToken(),
  file: data,
  graph_id: dialogueGraphId,
  formFieldName: formFieldName,
});

export const uploadFileFulfilled = (url, formFieldName) => ({
  type: Actions.UPLOAD_FILE_FULFILLED,
  url: url,
  formFieldName: formFieldName,
});

export const uploadTheme = (data, dialogueGraphId) => ({
  type: Actions.UPLOAD_THEME_CONFIG,
  token: getKeycloakToken(),
  payload: { config: data, graph_id: dialogueGraphId },
  formFieldName: "config",
});

export const uploadThemeFulfilled = (data) => ({
  type: Actions.UPLOAD_THEME_CONFIG_FULFILLED,
});

export const fetchTheme = (dialogueGraphId) => ({
  type: Actions.FETCH_THEME_CONFIG,
  payload: { graph_id: dialogueGraphId },
  token: getKeycloakToken(),
});

export const fetchThemeFulfilled = (data) => ({
  type: Actions.FETCH_THEME_CONFIG_FULFILLED,
  config: data,
});

export const fetchFiles = () => ({
  type: Actions.FETCH_FILES,
  token: getKeycloakToken(),
});

export const fetchFilesFulfilled = () => ({
  type: Actions.FETCH_FILES_FULFILLED,
});
