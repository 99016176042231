import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";

const PageBackdrop = (props: { open?: any; sx?: any }) => {
  const [open, setOpen] = useState(props.open);
  const theme = useTheme();

  const handleClose = () => setOpen(!open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Backdrop
      sx={{
        color: theme.palette.primary.main,
        zIndex: theme.zIndex.drawer + 1,
        background: "#CCCC",
        ...props.sx,
      }}
      open={open}
      onClick={handleClose}
    >
      <CircularProgress color="inherit" size={92} />
    </Backdrop>
  );
};

export default PageBackdrop;
