import Actions from "../constants";

export const setDrawerState = (currentState, action) => {
  if (action.type !== Actions.SET_DRAWER_STATE) {
    return currentState;
  }

  return action.payload;
};

export const setConversationSidebarState = (currentState, action) => {
  if (action.type !== Actions.SET_CONVERSATION_SIDEBAR_STATE) {
    return currentState;
  }

  return action.payload;
};
export const setLanguage = (currentState, action) => {
  if (action.type !== Actions.SET_LANGUAGE) {
    return currentState;
  }

  return action.payload;
};

export const setTenant = (currentState, action) => {
  if (action.type !== Actions.SET_TENANT) {
    return currentState;
  }

  return action.payload;
};
