import { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/ssr";
import { useStoreState } from "store/hooks";
import { ROLES } from "lib/constants";

export const useKeycloakUserRoles = () => {
  const { keycloak } = useKeycloak();
  const [roles, setRoles] = useState<string[]>();

  useEffect(() => {
    // @ts-ignore
    const parsedToken = keycloak?.idTokenParsed;
    if (parsedToken) {
      setRoles(parsedToken.roles);
    }
  }, [keycloak]);

  return roles;
};

const useUserRoles = () => {
  const keycloakRoles = useKeycloakUserRoles();
  const [roles, setRoles] = useState<string[]>();
  const userSettings = useStoreState((state) => state.userSettings);

  useEffect(() => {
    if (keycloakRoles) {
      setRoles(
        userSettings?.simulateUser
          ? keycloakRoles.filter((role) => role !== ROLES.ADMIN)
          : keycloakRoles
      );
    }
  }, [keycloakRoles, userSettings]);

  return roles;
};

export default useUserRoles;
