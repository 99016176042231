import { combineEpics } from "redux-observable";
import {
  fetchDialoguesEpic,
  createDialogueGraphEpic,
  createFormDialogueGraphEpic,
  fetchAllDialoguesEpic,
  fetchAllUrlsEpic,
  clearDialogueGraphEpic,
  saveDialogueGraphData,
  saveDialogueGraphInfo,
  publishDialogueGraphData,
  fetchActionNodeTemplate,
  revertDialogueGraphData,
  refetchDialogueGraphEpic,
  fetchDialogueVariablesEpic,
  fetchAllDialogueBlueprintsEpic,
  copyDialogueGraphEpic,
  copyDialogueGraphTemplateEpic,
  deleteDialogueGraphsEpic,
  fetchProactiveMessageEpic,
  saveProactiveMessageEpic,
  saveEedNotificationData,
  fetchEedNotificationData,
  trainDialogueGraphData,
  setupAutoDialogueGraphEpic,
} from "./dialogues";
import {
  fetchConversationTracker,
  fetchConversationIdentity,
  saveTenantIdentityEpic,
  createNoteEpic,
  createEventEpic,
  fetchConversationsEpic,
  updateConversationEpic,
  fetchConversationEpic,
  updateConversationReadEpic,
  updatePostEpic,
  fetchTranslatedConversationEpic,
  fetchConversationAttachmentsEpic,
  fetchAttachmentEpic,
  fetchTicketAnswerEpic,
  archiveConversationEpic,
} from "./conversations";
import { updateEventEpic } from "./events";
import { fetchSynonymsEpic } from "./synonyms";
import { fetchTicketsEpic, patchTicketEpic } from "./tickets";
import {
  fetchRentalUnitTicketsEpic,
  patchRentalUnitTicketsEpic,
  fetchRentalUnitTicketCountByStatusEpic,
} from "./rentalUnitTickets";
import { fetchRentalUnitTenantsEpic } from "./rentalUnitTenants";
import { fetchRentalUnitArticlesEpic } from "./rentalUnitArticles";
import {
  fetchFacebookPagesEpic,
  fetchDialogueChannelLimitations,
  fetchDialogueChannelShareLinks,
  createFacebookPageEpic,
  fetchUsedFacebookPageEpic,
  deleteFacebookPageEpic,
  createWebChannelConfigEpic,
  fetchUsedWebChannelConfigEpic,
  deleteWebChannelConfigEpic,
} from "./channels";
import { addUtterance } from "./utterances";
import { uploadFile, fetchFiles, uploadTheme, fetchTheme } from "./files";
import { fetchFaqsEpic, fetchTrainStatusEpic } from "./faqs";
import { fetchJumpGraphsEpic } from "./jumpGraphs";
import {
  fetchCannedResponsesEpic,
  fetchCannedResponseEpic,
  createCannedResponsesEpic,
  deleteCannedResponseEpic,
} from "./cannedResponses";
import {
  fetchLabelsEpic,
  fetchLabelEpic,
  createLabelsEpic,
  deleteLabelEpic,
  addLabelToConversationEpic,
  deleteLabelFromConversationEpic,
  fetchLabelsForConversationEpic,
} from "./labels";
import {
  fetchCategoriesEpic,
  fetchTopicsEpic,
  fetchReasonsEpic,
  fetchProblemsEpic,
  createTagCategoryEpic,
  createTagElementEpic,
  editTagElementEpic,
  addTagToMessageEpic,
  fetchTagsBySenderIdEpic,
  deleteTagFromMessageEpic,
  fetchTagsEpic,
  changeReviewStatusEpic,
  changePriorityEpic,
  addCommentToTagEpic,
  addAddtitionalAttributesEpic,
} from "./tags";
import {
  fetchCaseStatusValuesEpic,
  changeConversationCaseStatusEpic,
} from "./cases";
import {
  fetchContactsEpic,
  fetchContactEpic,
  createContactsEpic,
  deleteContactEpic,
  mergeContactEpic,
  syncContactEpic,
} from "./contacts";
import {
  fetchUserSettingsEpic,
  fetchUsersEpic,
  saveUserSettingsEpic,
  createUsersEpic,
  fetchUserEpic,
  deleteUserEpic,
  updateUserEpic,
  resendUserEmailVerificationEpic,
  resetUserPasswordEpic,
} from "./users";
import {
  fetchOngoingCampaignEpic,
  createOneOffCampaignsEpic,
  createOngoingCampaignsEpic,
  deleteOneOffCampaignEpic,
  deleteOngoingCampaignEpic,
  fetchOneOffCampaignsEpic,
  fetchOneOffCampaignEpic,
  fetchOngoingCampaignsEpic,
  fetchCampaignTypesEpic,
} from "./campaigns";
import { parseMessageEpic } from "./nlp";
import { fetchDecisionTreesEpic } from "./decisionTrees";
import {
  readUtteranceEpic,
  upsertUtteranceEpic,
  archiveConversationsEpic,
  disableClassicVersionEpic,
  deleteAllQnasEpic,
  uploadQnasEpic,
  uploadQnasLlmEpic,
  downloadQnasEpic,
  extractQnasEpic,
  generateReformulationsEpic,
  initiateClassicVersionEpic,
  fetchTriggerNodesBySentenceEpic,
  editSelectedTriggerNodesEpic,
} from "./admin";
import {
  fetchMSLoginLinkEpic,
  fetchMSLoginStateEpic,
  unlinkMSAccountEpic,
} from "store/epics/integrations";
import {
  downloadDocumentEpic,
  deleteDocumentEpic,
  fetchDocumentEpic,
  fetchDocumentsInfoEpic,
  updateDocumentEpic,
  uploadDocumentEpic,
  fetchDocumentsUsageEpic,
  documentSearchEpic,
  fetchUserConversationsEpic,
  upsertUserConversationsEpic,
} from "./smartDoc/documents";
import {
  deleteCollectionEpic,
  fetchCollectionEpic,
  fetchCollectionsEpic,
  upsertCollectionEpic,
} from "./smartDoc/collections";

export default combineEpics(
  fetchDialoguesEpic,
  createDialogueGraphEpic,
  createFormDialogueGraphEpic,
  saveDialogueGraphData,
  saveDialogueGraphInfo,
  publishDialogueGraphData,
  revertDialogueGraphData,
  refetchDialogueGraphEpic,
  fetchAllDialoguesEpic,
  fetchAllUrlsEpic,
  clearDialogueGraphEpic,
  fetchTicketsEpic,
  patchTicketEpic,
  fetchRentalUnitTenantsEpic,
  fetchRentalUnitArticlesEpic,
  fetchRentalUnitTicketsEpic,
  patchRentalUnitTicketsEpic,
  fetchRentalUnitTicketCountByStatusEpic,
  fetchFaqsEpic,
  fetchTrainStatusEpic,
  fetchJumpGraphsEpic,
  fetchSynonymsEpic,
  addUtterance,
  fetchActionNodeTemplate,
  fetchConversationTracker,
  uploadFile,
  uploadTheme,
  fetchFiles,
  fetchTheme,
  fetchDialogueVariablesEpic,
  fetchAllDialogueBlueprintsEpic,
  copyDialogueGraphEpic,
  copyDialogueGraphTemplateEpic,
  deleteDialogueGraphsEpic,
  fetchDialogueChannelLimitations,
  fetchDialogueChannelShareLinks,
  fetchFacebookPagesEpic,
  createFacebookPageEpic,
  fetchUsedFacebookPageEpic,
  deleteFacebookPageEpic,
  createWebChannelConfigEpic,
  fetchUsedWebChannelConfigEpic,
  deleteWebChannelConfigEpic,
  fetchProactiveMessageEpic,
  saveProactiveMessageEpic,
  fetchConversationIdentity,
  saveTenantIdentityEpic,
  fetchEedNotificationData,
  saveEedNotificationData,
  createNoteEpic,
  fetchCannedResponsesEpic,
  fetchCannedResponseEpic,
  createCannedResponsesEpic,
  deleteCannedResponseEpic,
  fetchLabelsEpic,
  fetchLabelEpic,
  createLabelsEpic,
  deleteLabelEpic,
  addLabelToConversationEpic,
  deleteLabelFromConversationEpic,
  fetchLabelsForConversationEpic,
  createEventEpic,
  fetchCaseStatusValuesEpic,
  changeConversationCaseStatusEpic,
  fetchConversationsEpic,
  fetchContactsEpic,
  fetchContactEpic,
  createContactsEpic,
  deleteContactEpic,
  mergeContactEpic,
  fetchUserSettingsEpic,
  saveUserSettingsEpic,
  createUsersEpic,
  fetchUsersEpic,
  deleteUserEpic,
  fetchUserEpic,
  updateUserEpic,
  resendUserEmailVerificationEpic,
  resetUserPasswordEpic,
  updateConversationEpic,
  fetchConversationEpic,
  fetchOngoingCampaignEpic,
  createOneOffCampaignsEpic,
  createOngoingCampaignsEpic,
  deleteOneOffCampaignEpic,
  deleteOngoingCampaignEpic,
  fetchOneOffCampaignsEpic,
  fetchOneOffCampaignEpic,
  fetchOngoingCampaignsEpic,
  updateConversationReadEpic,
  trainDialogueGraphData,
  parseMessageEpic,
  updateEventEpic,
  fetchDecisionTreesEpic,
  updatePostEpic,
  setupAutoDialogueGraphEpic,
  fetchTranslatedConversationEpic,
  fetchConversationAttachmentsEpic,
  fetchAttachmentEpic,
  fetchTicketAnswerEpic,
  fetchCampaignTypesEpic,
  readUtteranceEpic,
  upsertUtteranceEpic,
  archiveConversationsEpic,
  disableClassicVersionEpic,
  deleteAllQnasEpic,
  uploadQnasEpic,
    uploadQnasLlmEpic,
  downloadQnasEpic,
  extractQnasEpic,
  generateReformulationsEpic,
  initiateClassicVersionEpic,
  fetchTriggerNodesBySentenceEpic,
  editSelectedTriggerNodesEpic,
  fetchCategoriesEpic,
  fetchTopicsEpic,
  fetchReasonsEpic,
  fetchProblemsEpic,
  createTagCategoryEpic,
  createTagElementEpic,
  editTagElementEpic,
  addTagToMessageEpic,
  fetchTagsBySenderIdEpic,
  deleteTagFromMessageEpic,
  fetchTagsEpic,
  changeReviewStatusEpic,
  fetchMSLoginLinkEpic,
  fetchMSLoginStateEpic,
  unlinkMSAccountEpic,
  changeReviewStatusEpic,
  changePriorityEpic,
  addCommentToTagEpic,
  addAddtitionalAttributesEpic,
  syncContactEpic,
  downloadDocumentEpic,
  documentSearchEpic,
  deleteDocumentEpic,
  fetchDocumentEpic,
  fetchDocumentsInfoEpic,
  fetchDocumentsUsageEpic,
  updateDocumentEpic,
  uploadDocumentEpic,
  fetchCollectionsEpic,
  fetchCollectionEpic,
  upsertCollectionEpic,
  deleteCollectionEpic,
  fetchUserConversationsEpic,
  upsertUserConversationsEpic,
  archiveConversationEpic
);
