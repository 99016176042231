import {
  MonoTypeOperatorFunction,
  Observable,
  of,
  OperatorFunction,
} from "rxjs";
import { switchMap, catchError, debounceTime, concatMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../../constants";
import { errorMessage } from "../../actions/error";
import { ajax } from "rxjs/ajax";
import getConfig from "lib/config";
import { Action } from "redux";
import { showErrorSnackbar, showSuccessSnackbar } from "store/actions/snackbar";

import {
  deleteCollectionFulfilled,
  fetchCollections,
  setCollection,
  setCollections,
  upsertCollectionFulfilled,
} from "store/actions/smartDoc/collections";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_AGENT_HOST, REACT_APP_ACTION_HOST } = publicRuntimeConfig;

export const fetchCollectionsEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<unknown, any>
    ) => any;
  },
  state$: {
    value: {
      tenant: any;
    };
  }
) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_COLLECTIONS),
    debounceTime(100),
    switchMap((action: any) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/collections`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(setCollections(response.response.result));
        }),
        catchError((error) => {
          return of(setCollections(null), errorMessage(error.message));
        })
      );
    })
  );
};

export const fetchCollectionEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<unknown, any>
    ) => any;
  },
  state$: {
    value: {
      tenant: any;
    };
  }
) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_COLLECTION),
    debounceTime(100),
    switchMap((action: any) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/collections/${action.collection_id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(setCollection(response.response.result));
        }),
        catchError((error) => {
          return of(setCollection(null), errorMessage(error.message));
        })
      );
    })
  );
};

export const upsertCollectionEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<unknown, any>
    ) => any;
  },
  state$: {
    value: {
      tenant: any;
    };
  }
) => {
  return action$.pipe(
    ofType(ActionConstants.UPSERT_COLLECTION),
    debounceTime(0),
    switchMap((action: any) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/collections`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        //wenn keine id mitgeschickt wird, wird neue collection erstellt
        body: action.payload,
      }).pipe(
        concatMap((response) => {
          let snackBarMessage = "Snackbars.collectionUpdateSuccess";
          if (action.editCollectionId) {
            snackBarMessage = "Snackbars.collectionEditSuccess";
          }
          return of(
            upsertCollectionFulfilled(),
            showSuccessSnackbar(snackBarMessage, {
              vertical: "top",
              horizontal: "right",
            }),
            fetchCollections()
          );
        }),
        catchError((error) => {
          let snackBarMessage = "Snackbars.collectionUpdateError";
          if (action.editCollectionId) {
            snackBarMessage = "Snackbars.collectionEditError";
          }
          return of(
            errorMessage(error.message),
            showErrorSnackbar(snackBarMessage, {
              vertical: "top",
              horizontal: "right",
            })
          );
        })
      );
    })
  );
};

export const deleteCollectionEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<unknown, any>
    ) => any;
  },
  state$: {
    value: {
      tenant: any;
    };
  }
) => {
  return action$.pipe(
    ofType(ActionConstants.DELETE_COLLECTION),
    debounceTime(100),
    switchMap((action: any) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/collections/${action.collection_id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(
            deleteCollectionFulfilled(),
            showSuccessSnackbar("Snackbars.collectionDeletionSuccess", {
              vertical: "top",
              horizontal: "right",
            }),
            fetchCollections()
          );
        }),
        catchError((error) => {
          return of(
            errorMessage(error.message),
            showErrorSnackbar("Snackbars.collectionDeletionError", {
              vertical: "top",
              horizontal: "right",
            })
          );
        })
      );
    })
  );
};
