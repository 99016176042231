import Actions from "../../constants";
import { getKeycloakToken } from "lib/util";

export const uploadDocument = (data: any) => ({
  type: Actions.UPLOAD_DOCUMENT,
  payload: data,
  token: getKeycloakToken(),
});

export const fetchDocumentsInfo = () => ({
  type: Actions.FETCH_DOCUMENTS_INFO,
  token: getKeycloakToken(),
});

export const fetchDocumentsInfoFulfilled = () => ({
  type: Actions.FETCH_DOCUMENTS_INFO_FULFILLED,
});

export const fetchDocumentsUsage = () => ({
  type: Actions.FETCH_DOCUMENTS_USAGE,
  token: getKeycloakToken(),
});

export const setDocumentsInfo = (data: any) => ({
  type: Actions.SET_DOCUMENTS_INFO,
  payload: data,
});
export const setDocumentsUsage = (data: any) => ({
  type: Actions.SET_DOCUMENTS_USAGE,
  payload: data,
});

export const updateDocument = (data: any) => ({
  type: Actions.UPDATE_DOCUMENT,
  payload: data,
  token: getKeycloakToken(),
});

export const updateDocumentFulfilled = () => ({
  type: Actions.UPDATE_DOCUMENT_FULFILLED,
});

export const fetchDocument = (documentId: string) => ({
  type: Actions.FETCH_DOCUMENT,
  document_id: documentId,
  token: getKeycloakToken(),
});

export const fetchDocumentFulfilled = () => ({
  type: Actions.FETCH_DOCUMENT_FULFILLED,
});

export const fetchDocumentFailed = (error: any) => ({
  type: Actions.FETCH_DOCUMENT_FAILED,
  error: error,
});

export const setDocument = (data: any) => ({
  type: Actions.SET_DOCUMENT,
  payload: data,
});

export const deleteDocument = (documentId: string) => ({
  type: Actions.DELETE_DOCUMENT,
  document_id: documentId,
  token: getKeycloakToken(),
});

export const deleteDocumentFulfilled = () => ({
  type: Actions.DELETE_DOCUMENT_FULFILLED,
});

export const fetchDownloadDocument = (documentId: string) => ({
  type: Actions.DOWNLOAD_DOCUMENT,
  document_id: documentId,
  token: getKeycloakToken(),
});

export const fetchDocumentDownloadFulfilled = () => ({
  type: Actions.DOWNLOAD_DOCUMENT_FULFILLED,
});

export const fetchDocumentDownloadFailed = (error: any) => ({
  type: Actions.DOWNLOAD_DOCUMENT_FAILED,
  error,
});

export const setDownloadDocument = (data: any) => ({
  type: Actions.SET_DOWNLOAD_DOCUMENT,
  payload: data,
  token: getKeycloakToken(),
});

export const documentSearch = (data: any) => ({
  type: Actions.DOCUMENT_SEARCH,
  payload: data,
  token: getKeycloakToken(),
});

export const setDocumentViewport = (data: any) => ({
  type: Actions.SET_DOCUMENT_VIEWPORT,
  payload: data,
});

export const fetchUserConversations = (payload?: {
  documentId?: string | undefined | null;
  collectionId?: string | undefined | null;
}) => {
  return {
    type: Actions.FETCH_USER_CONVERSATIONS,
    documentId: payload && payload.documentId ? payload.documentId : null,
    collectionId: payload && payload.collectionId ? payload.collectionId : null,
    token: getKeycloakToken(),
  };
};

export const fetchUserConversationsFulfilled = () => ({
  type: Actions.FETCH_USER_CONVERSATIONS_FULFILLED,
});

export const fetchUserConversationsFailed = (error: any) => ({
  type: Actions.FETCH_USER_CONVERSATIONS_FAILED,
  error,
});
export const setUserConversations = (data: any) => ({
  type: Actions.SET_USER_CONVERSATIONS,
  payload: data,
});

export const upsertUserConversations = (
  data: any,
  documentId: string | null | undefined,
  editChatId?: string | null | undefined,
  collectionId?: string | null | undefined
) => ({
  type: Actions.UPSERT_USER_CONVERSATIONS,
  payload: data,
  documentId: documentId ? documentId : null,
  editChatId: editChatId ? editChatId : null,
  collectionId: collectionId ? collectionId : null,
  token: getKeycloakToken(),
});

export const setDocumentConversationId = (data: any) => ({
  type: Actions.SET_DOCUMENT_CONVERSATION_ID,
  payload: data,
});

export const resetDocumentViewFetchStatus = () => ({
  type: Actions.RESET_DOCUMENT_VIEW_FETCH_STATUS,
});

export const setPdfData = (data: any) => ({
  type: Actions.SET_PDF_DATA,
  payload: data,
});
export const setMessageLimitReached = (data: any) => ({
  type: Actions.SET_MESSAGE_LIMIT_REACHED,
  payload: data,
});

export const setAnnotations = (data: any) => ({
  type: Actions.SET_ANNOTATIONS,
  payload: data,
});

export const setAnnotationsHighlightColor = (data: any) => ({
  type: Actions.SET_ANNOTATIONS_HIGHLIGHTCOLOR,
  payload: data,
});
export const setStorageLimitReached = (data: any) => ({
  type: Actions.SET_STORAGE_LIMIT_REACHED,
  payload: data,
});
