import Actions from "../../constants";

export const setCollection = (
  currentState: any,
  action: { type: any; payload: any }
) => {
  if (action.type !== Actions.SET_COLLECTION) {
    return currentState;
  }

  return action.payload;
};
export const setCollections = (
  currentState: any,
  action: { type: any; payload: any }
) => {
  if (action.type !== Actions.SET_COLLECTIONS) {
    return currentState;
  }

  return action.payload;
};

export const setIsTrashCollection = (
  currentState: any,
  action: {
    type: any;
    payload: any;
  }
) => {
  if (action.type !== Actions.SET_IS_TRASH_COLLECTION) {
    return currentState;
  }

  return action.payload;
};
