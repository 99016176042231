import Actions from "../../constants";
import { getKeycloakToken } from "lib/util";

export const fetchCollections = () => ({
  type: Actions.FETCH_COLLECTIONS,
  token: getKeycloakToken(),
});

export const setCollections = (data: any) => ({
  type: Actions.SET_COLLECTIONS,
  payload: data,
});

export const fetchCollection = (collectionId: string) => ({
  type: Actions.FETCH_COLLECTION,
  collection_id: collectionId,
  token: getKeycloakToken(),
});

export const setCollection = (data: any) => ({
  type: Actions.SET_COLLECTION,
  payload: data,
});

export const upsertCollection = (
  data: any,
  editCollectionId?: string | null | undefined
) => ({
  type: Actions.UPSERT_COLLECTION,
  payload: data,
  editCollectionId: editCollectionId ? editCollectionId : null,
  token: getKeycloakToken(),
});
export const upsertCollectionFulfilled = () => ({
  type: Actions.UPSERT_COLLECTION_FULFILLED,
});

export const deleteCollection = (collectionId: string) => ({
  type: Actions.DELETE_COLLECTION,
  collection_id: collectionId,
  token: getKeycloakToken(),
});
export const deleteCollectionFulfilled = () => ({
  type: Actions.DELETE_COLLECTION_FULFILLED,
});

export const setIsTrashCollection = (collectionId: string) => {
  const isTrashCollection =
    collectionId === "00000000-0000-0000-0000-000000000002";

  return {
    type: Actions.SET_IS_TRASH_COLLECTION,
    payload: isTrashCollection,
  };
};
