import Actions from "../constants";
import { getKeycloakToken } from "lib/util";

export const qnaUpload = (payload) => ({
  type: Actions.FETCH_QNA_UPLOAD,
  token: getKeycloakToken(),
  payload: payload,
});

export const qnaLlmUpload = (payload) => ({
  type: Actions.FETCH_QNA_LLM_UPLOAD,
  token: getKeycloakToken(),
  payload: payload,
});

export const setQnaUpload = (payload) => ({
  type: Actions.SET_QNA_UPLOAD,
  payload: payload,
});

export const qnaDownload = () => ({
  type: Actions.FETCH_QNA_DOWNLOAD,
  token: getKeycloakToken(),
});

export const qnaExtraction = (payload) => ({
  type: Actions.FETCH_QNA_EXTRACTION,
  token: getKeycloakToken(),
  payload: payload,
});

export const reformulationGeneration = (payload) => ({
  type: Actions.REFORMULATION_GENERATION,
  token: getKeycloakToken(),
  payload: payload,
});

export const setQnaDownload = (payload) => ({
  type: Actions.SET_QNA_DOWNLOAD,
  payload: payload,
});

export const setAdminResponse = (payload) => ({
  type: Actions.SET_ADMIN_RESPONSE,
  payload: payload,
});

export const qnaDeleteAll = () => ({
  type: Actions.DELETE_QNAS,
  token: getKeycloakToken(),
});

export const setQnaDeleteAll = (payload) => ({
  type: Actions.SET_DELETE_QNAS,
  payload: payload,
});

export const utteranceUpsert = (payload) => ({
  type: Actions.FETCH_UTTERANCE_UPSERT,
  token: getKeycloakToken(),
  payload: payload,
});

export const setUtteranceUpsert = (payload) => ({
  type: Actions.SET_UTTERANCE_UPSERT,
  payload: payload,
});

export const utteranceRead = (payload) => ({
  type: Actions.FETCH_UTTERANCE,
  token: getKeycloakToken(),
  payload: payload,
});

export const setUtteranceRead = (payload) => ({
  type: Actions.SET_UTTERANCE,
  payload: payload,
});

export const initiateClassicVersion = (payload) => ({
  type: Actions.FETCH_INITIATE_CLASSIC_VERSION,
  token: getKeycloakToken(),
  payload: payload,
});

export const setInitiateClassicVersion = (payload) => ({
  type: Actions.SET_INITIATE_CLASSIC_VERSION,
  payload: payload,
});

export const disableClassicVersion = (payload) => ({
  type: Actions.FETCH_DISABLE_CLASSIC_VERSION,
  token: getKeycloakToken(),
  payload: payload,
});

export const setDisableClassicVersion = (payload) => ({
  type: Actions.SET_DISABLE_CLASSIC_VERSION,
  payload: payload,
});

export const conversationArchive = () => ({
  type: Actions.FETCH_ARCHIVE_CONVERSATIONS,
  token: getKeycloakToken(),
});

export const setConversationsArchive = () => ({
  type: Actions.SET_ARCHIVE_CONVERSATIONS,
});

export const fetchTriggerNodesBySentence = (payload) => ({
  type: Actions.FETCH_TRIGGER_NODES_BY_SENTENCE,
  payload: payload,
});

export const setTriggerNodesFetchedBySentence = (payload) => ({
  type: Actions.SET_TRIGGER_NODES_FETCHED_BY_SENTENCE,
  payload: payload,
});

export const editSelectedTriggerNodes = (payload) => ({
  type: Actions.EDIT_SELECTED_TRIGGER_NODES,
  token: getKeycloakToken(),
  payload: payload,
});
