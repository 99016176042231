import { of } from "rxjs";
import {
  catchError,
  concatMap,
  debounceTime,
  map,
  switchMap,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";
import {
  setAdminResponse,
  setConversationsArchive,
  setDisableClassicVersion,
  setInitiateClassicVersion,
  setQnaDeleteAll,
  setQnaUpload,
  setTriggerNodesFetchedBySentence,
  setUtteranceRead,
  setUtteranceUpsert,
} from "../actions/admin";
import getConfig from "../../lib/config";
import { showErrorSnackbar, showSuccessSnackbar } from "../actions/snackbar";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST } = publicRuntimeConfig;

export const archiveConversationsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_ARCHIVE_CONVERSATIONS),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/conversations/archive`,
        method: "PATCH",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          const responseStatus = {
            response: true,
            status: response.status,
            action: "conversations_archive",
          };

          return of(
            setAdminResponse(responseStatus),
            setConversationsArchive(response.response),
            showSuccessSnackbar("Snackbars.archiveConversationsSuccess")
          );
        }),
        catchError((error) => {
          return of(
            errorMessage(error.message),
            showErrorSnackbar("Snackbars.archiveConversationsError")
          );
        })
      );
    })
  );
};

export const initiateClassicVersionEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_INITIATE_CLASSIC_VERSION),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/admin/migrations/initiate_classic_version?add_faqs=${
          action.payload?.addFaqs || false
        }`,
        method: "GET",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          const responseStatus = {
            response: true,
            status: response.status,
            action: "initiate_classic_version",
          };

          return of(
            setAdminResponse(responseStatus),
            setInitiateClassicVersion(response.response),
            showSuccessSnackbar("Snackbars.initiateClassicVersionSuccess")
          );
        }),
        catchError((error) => {
          return of(
            errorMessage(error.message),
            setAdminResponse("error"),
            showErrorSnackbar("Snackbars.initiateClassicVersionError")
          );
        })
      );
    })
  );
};

export const disableClassicVersionEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_DISABLE_CLASSIC_VERSION),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/admin/migrations/disable_classic_version?delete_tickets=${
          action.payload?.deleteTickets || false
        }`,
        method: "GET",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          const responseStatus = {
            response: true,
            status: response.status,
            action: "disable_classic_version",
          };

          return of(
            setAdminResponse(responseStatus),
            setDisableClassicVersion(response.response),
            showSuccessSnackbar("Snackbars.disableClassicVersionSuccess")
          );
        }),
        catchError((error) => {
          return of(
            errorMessage(error.message),
            setAdminResponse("error"),
            showErrorSnackbar("Snackbars.disableClassicVersionError")
          );
        })
      );
    })
  );
};

export const deleteAllQnasEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.DELETE_QNAS),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/templates/questions_and_answers?language=${state$.value.language}`,
        method: "DELETE",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          const responseStatus = {
            response: true,
            status: response.status,
            action: "qna_delete_all",
          };

          return of(
            setAdminResponse(responseStatus),
            showSuccessSnackbar("Snackbars.qnaDeleteAllSuccess")
          );
        }),
        catchError((error) => {
          return of(
            errorMessage(error.message),
            setAdminResponse("error"),
            showErrorSnackbar("Snackbars.qnaDeleteAllError")
          );
        })
      );
    })
  );
};

export const uploadQnasEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_QNA_UPLOAD),
    debounceTime(0),
    switchMap((action) => {
      const body = new FormData();
      body.append("file", action.payload.file);
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/templates/questions_and_answers/upload?language=${state$.value.language}`,
        method: "POST",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body,
      }).pipe(
        concatMap((response) => {
          const responseStatus = {
            response: true,
            status: response.status,
            action: "qna_upload",
          };
          return of(
            setAdminResponse(responseStatus),
            showSuccessSnackbar("Snackbars.qnaUploadSuccess")
          );
        }),
        catchError((error) => {
          return of(
            errorMessage(error.message),
            setAdminResponse("error"),
            showErrorSnackbar("Snackbars.qnaUploadError")
          );
        })
      );
    })
  );
};

export const uploadQnasLlmEpic = (action$, state$) => {
    return action$.pipe(
        ofType(ActionConstants.FETCH_QNA_LLM_UPLOAD),
        debounceTime(0),
        switchMap((action) => {
            const body = new FormData();
            body.append("file", action.payload.file);
            return ajax({
                url: `${REACT_APP_ACTION_HOST}/templates/questions_and_answers/upload_smartdoc_faq?language=${state$.value.language}`,
                method: "POST",
                headers: {
                    Authorization: action.token,
                    Tenant_Realm: state$.value.tenant,
                    Type_Origin: "dashboard",
                },
                body,
            }).pipe(
                concatMap((response) => {
                    const responseStatus = {
                        response: true,
                        status: response.status,
                        action: "qna_llm_upload",
                    };
                    return of(
                        setAdminResponse(responseStatus),
                        showSuccessSnackbar("Snackbars.qnaUploadSuccess")
                    );
                }),
                catchError((error) => {
                    return of(
                        errorMessage(error.message),
                        setAdminResponse("error"),
                        showErrorSnackbar("Snackbars.qnaUploadError")
                    );
                })
            );
        })
    );
};


export const downloadQnasEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_QNA_DOWNLOAD),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/templates/questions_and_answers/download?language=${state$.value.language}`,
        method: "GET",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
          Accept: "text/csv",
        },
        responseType: "blob",
      }).pipe(
        concatMap((response) => {
          const responseStatus = {
            response: true,
            status: response.status,
            action: "qna_download",
            file: response.response,
          };
          return of(
            setAdminResponse(responseStatus),
            showSuccessSnackbar("Snackbars.qnaDownloadSuccess")
          );
        }),
        catchError((error) => {
          return of(
            errorMessage(error.message),
            setAdminResponse("error"),
            showErrorSnackbar("Snackbars.downloadError")
          );
        })
      );
    })
  );
};

export const extractQnasEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_QNA_EXTRACTION),
    debounceTime(0),
    switchMap((action) => {
      if (action.payload.upload === true) {
        return ajax({
          url: `${REACT_APP_ACTION_HOST}/llm/questions_and_answers_extraction/upload?count=${action.payload.count}&url=${action.payload.url}&language=${state$.value.language}`,
          method: "GET",
          headers: {
            Authorization: action.token,
            Tenant_Realm: state$.value.tenant,
            Type_Origin: "dashboard",
          },
        }).pipe(
          concatMap((response) => {
            const responseStatus = {
              response: true,
              status: response.status,
              action: "qna_extraction",
            };
            return of(
              setAdminResponse(responseStatus),
              showSuccessSnackbar("Snackbars.extractQnasSuccess")
            );
          }),
          catchError((error) => {
            return of(
              errorMessage(error.message),
              setAdminResponse("error"),
              showErrorSnackbar("Snackbars.extractQnasError")
            );
          })
        );
      } else {
        return ajax({
          url: `${REACT_APP_ACTION_HOST}/llm/questions_and_answers_extraction/download?count=${action.payload.count}&url=${action.payload.url}&language=${state$.value.language}`,
          method: "GET",
          headers: {
            Authorization: action.token,
            Tenant_Realm: state$.value.tenant,
            Type_Origin: "dashboard",
            Accept: "text/csv",
          },
          responseType: "blob",
        }).pipe(
          concatMap((response) => {
            const responseStatus = {
              response: true,
              status: response.status,
              action: "qna_extraction_download",
              file: response.response,
            };
            return of(
              setAdminResponse(responseStatus),
              showSuccessSnackbar("Snackbars.extractQnasSuccess")
            );
          }),
          catchError((error) => {
            return of(
              errorMessage(error.message),
              setAdminResponse("error"),
              showErrorSnackbar("Snackbars.extractQnasError")
            );
          })
        );
      }
    })
  );
};

export const generateReformulationsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.REFORMULATION_GENERATION),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/llm/reformulations/download?count=${action.payload.count}&sentence=${action.payload.sentence}&language=${state$.value.language}`,
        method: "GET",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
          Accept: "text/csv",
        },
        responseType: "blob",
      }).pipe(
        concatMap((response) => {
          const responseStatus = {
            response: true,
            status: response.status,
            action: "reformulation_generation",
            file: response.response,
          };
          return of(
            setAdminResponse(responseStatus),
            showSuccessSnackbar("Snackbars.generateReformulationsSuccess")
          );
        }),
        catchError((error) => {
          return of(
            errorMessage(error.message),
            setAdminResponse("error"),
            showErrorSnackbar("Snackbars.generateReformulationsError")
          );
        })
      );
    })
  );
};

export const readUtteranceEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_UTTERANCE),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/template/${action.payload.template}?language=${state$.value.language}`,
        method: "GET",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setUtteranceRead(response.response);
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const upsertUtteranceEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_UTTERANCE_UPSERT),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/templates/${action.payload.utterance_key}?language=${state$.value.language}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: action.payload.body,
      }).pipe(
        concatMap((response) => {
          const responseStatus = {
            response: true,
            status: response.status,
            action: "utterance_upsert",
          };

          return of(
            setUtteranceUpsert(action.payload),
            setAdminResponse(responseStatus),
            showSuccessSnackbar("Snackbars.utteranceUpsertSuccess")
          );
        }),
        catchError((error) => {
          return of(
            errorMessage(error.message),
            setAdminResponse("error"),
            showErrorSnackbar("Snackbars.utteranceUpsertError")
          );
        })
      );
    })
  );
};

export const fetchTriggerNodesBySentenceEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_TRIGGER_NODES_BY_SENTENCE),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/trigger/by_sentence?language=${state$.value.language}&sentence=${action.payload}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(
            setTriggerNodesFetchedBySentence(response.response),
            showSuccessSnackbar(
              "Snackbars.fetchedTriggerNodesBySentenceSuccess"
            )
          );
        }),
        catchError((error) => {
          return of(
            errorMessage(error.message),
            setAdminResponse("error"),
            showErrorSnackbar("Snackbars.fetchedTriggerNodesBySentenceError")
          );
        })
      );
    })
  );
};

export const editSelectedTriggerNodesEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.EDIT_SELECTED_TRIGGER_NODES),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/trigger/sentences?language=${state$.value.language}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: action.payload,
      }).pipe(
        concatMap((response) => {
          const responseStatus = {
            response: true,
            status: response.status,
            action: "utterance_upsert",
          };
          return of(
            setAdminResponse(responseStatus),
            showSuccessSnackbar("Snackbars.editSelectedTriggerNodeSuccess")
          );
        }),
        catchError((error) => {
          return of(
            errorMessage(error.message),
            setAdminResponse("error"),
            showErrorSnackbar("Snackbars.editSelectedTriggerNodeError")
          );
        })
      );
    })
  );
};
