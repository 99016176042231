import React, { useEffect, useState } from "react";
import { ListItemButtonProps } from "@mui/material/ListItemButton";
import { Theme } from "@mui/material/styles/createTheme";
import ListSubheader from "@mui/material/ListSubheader";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Link from "next/link";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MoveToInboxOutlinedIcon from "@mui/icons-material/MoveToInboxOutlined";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import MapIcon from "@mui/icons-material/Map";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RateReviewIcon from "@mui/icons-material/RateReview";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import Dashboard from "@mui/icons-material/Dashboard";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ContactsIcon from "@mui/icons-material/Contacts";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import CampaignIcon from "@mui/icons-material/Campaign";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import AllInboxOutlinedIcon from "@mui/icons-material/AllInboxOutlined";
import PolylineIcon from "@mui/icons-material/Polyline";
import PolylineOutlinedIcon from "@mui/icons-material/PolylineOutlined";
import InsightsIcon from "@mui/icons-material/Insights";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";

import { styled, useTheme } from "@mui/material/styles";
import { Logo } from "./Logo";
import { useStoreState } from "store/hooks";
import { drawerWidth, drawerWidthCollapsed } from "../constants";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import { useKeycloak } from "@react-keycloak/ssr";
import Box from "@mui/material/Box";
import { isLinkSelected, WHITELABEL_INSTANCES } from "lib/util";
import useUserRoles from "hooks/useUserRoles";
import getConfig from "lib/config";
import { ROLES } from "lib/constants";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_HELPDOCS_URL, REACT_APP_TENANT } = publicRuntimeConfig;

const openedMixin = (theme: Theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${drawerWidthCollapsed}px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${drawerWidthCollapsed}px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(1, 1),
  "& svg": {
    marginTop: 0,
  },
  minHeight: 48,
  // necessary for content to be below app bar
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }: any) => ({
  width: drawerWidth,
  flexShrink: 0,
  zIndex: 9,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface LinkListItemProps extends ListItemButtonProps {
  open: boolean;
  label: string;
  theme: Theme;
  icon: any;
  selected?: boolean;
}

const renderTooltip = (
  children: React.ReactElement,
  title: string,
  show: boolean
) => {
  return show ? (
    <Tooltip title={title} placement="right">
      {children}
    </Tooltip>
  ) : (
    children
  );
};

const LinkListItem: React.FC<LinkListItemProps> = React.forwardRef(
  ({ theme, icon, label, open, onClick, selected = false }, ref) => {
    return renderTooltip(
      <ListItem
        selected={selected}
        onClick={onClick}
        button
        sx={{
          padding: theme.spacing(1),
          paddingLeft: "10px",
          "&.Mui-selected": {
            borderLeftColor: theme.palette.secondary.main,
            borderLeftWidth: "2px",
            borderLeftStyle: "solid",
          },
        }}
        ref={ref}
      >
        <ListItemIcon
          sx={{
            color: "white",
            "& svg": {
              fontSize: "1.1em",
            },
            minWidth: "32px",
          }}
        >
          {icon}
        </ListItemIcon>
        <Fade timeout={{ enter: 1000, exit: 100 }} in={open}>
          <ListItemText
            sx={{ margin: 0, color: "white", "& span": { lineHeight: 1 } }}
            primary={label}
          />
        </Fade>
      </ListItem>,
      label,
      !open
    );
  }
);
LinkListItem.displayName = "LinkListItem";

const NavSideBar = (props: {
  onDrawerToggle: (arg0: boolean) => void;
  onClickMessageDebugger: () => void;
  onClickAdvancedSetup: () => void;
  initialOpen: boolean;
}) => {
  const drawerState = useStoreState((state) => state.drawerState);
  const router = useRouter();
  const theme = useTheme();
  const { keycloak } = useKeycloak();
  const roles = useUserRoles();
  const t = useTranslations();

  const { tenant } = router.query;

  const [open, setOpen] = useState(props.initialOpen);

  useEffect(() => {
    if (open !== drawerState) {
      setOpen(drawerState);
    }
  }, [drawerState]);

  const handleClose = () => {
    setOpen(!open);
    props.onDrawerToggle(!open);
  };
  const management_statistics_role = roles?.some((r) =>
    [ROLES.PAGE.MANAGEMENT_STATISTICS].includes(r)
  );
  const design_access_only = roles?.some((r) =>
    [ROLES.PAGE.DIALOGUE_EDITOR_DESIGN].includes(r)
  );
  const rolesWithPage = roles?.filter((entry) => entry.startsWith("page_"));

  const smartDoc_access_only =
    (rolesWithPage?.length || 0) > 0 &&
    rolesWithPage?.every((entry) => entry.includes("smartdoc"));

  const NavLinks = () => {
    if (management_statistics_role) {
      return [
        {
          label: t("analyze"),
          items: [
            {
              label: t("management_statistics"),
              href: `/${tenant}/management-statistics`,
              icon: <InsightsIcon />,
              iconInactive: <InsightsIcon />,
            },
          ],
        },
      ];
    }
    if (design_access_only) {
      return [
        {
          label: t("build"),
          items: [
            {
              label: t("dialogue_editor"),
              href: `/${tenant}/dialogue-editor/0/design`,
              icon: <AccountTreeIcon />,
              iconInactive: <AccountTreeOutlinedIcon />,
            },
          ],
        },
      ];
    }
    if (smartDoc_access_only) {
      return [
        {
          label: t("interact"),
          items: [
            {
              label: t("smart_doc"),
              href: `/${tenant}/smart-doc/documents`,
              icon: <LocalLibraryIcon />,
              iconInactive: <LocalLibraryOutlinedIcon />,
            },
          ],
        },
      ];
    }

    return [
      {
        items: [
          {
            label: t("dashboard"),
            href: `/${tenant}`,
            icon: <Dashboard />,
            iconInactive: <DashboardOutlinedIcon />,
          },
        ],
      },
      ...(roles?.some((r) =>
        [
          ROLES.ADMIN,
          ROLES.PAGE.CONVERSATIONS,
          ROLES.PAGE.CONTACTS,
          ROLES.PAGE.CAMPAIGNS,
          ROLES.PAGE.SMARTDOC,
        ].includes(r)
      )
        ? [
            {
              label: t("interact"),
              items: [
                ...(roles?.some((r) =>
                  [ROLES.ADMIN, ROLES.PAGE.CONVERSATIONS].includes(r)
                )
                  ? [
                      {
                        label: t("conversations"),
                        href: `/${tenant}/conversations`,
                        icon: <QuestionAnswerIcon />,
                        iconInactive: <QuestionAnswerOutlinedIcon />,
                      },
                    ]
                  : []),
                ...(roles?.some((r) => [ROLES.ADMIN].includes(r))
                  ? [
                      {
                        label: "Tickets",
                        href: `/${tenant}/tickets`,
                        icon: <AllInboxIcon />,
                        iconInactive: <AllInboxOutlinedIcon />,
                      },
                      {
                        label: t("contacts"),
                        href: `/${tenant}/contacts`,
                        icon: <ContactsIcon />,
                        iconInactive: <ContactsOutlinedIcon />,
                      },
                    ]
                  : []),
                ...(roles?.some((r) =>
                  [ROLES.ADMIN, ROLES.PAGE.CAMPAIGNS].includes(r)
                )
                  ? [
                      {
                        label: t("campaigns"),
                        href: `/${tenant}/campaigns/proactive-events`,
                        icon: <CampaignIcon />,
                        iconInactive: <CampaignOutlinedIcon />,
                      },
                    ]
                  : []),
                ...(roles?.some((r) =>
                  [ROLES.ADMIN, ROLES.PAGE.SMARTDOC].includes(r)
                )
                  ? [
                      {
                        label: t("smart_doc"),
                        href: `/${tenant}/smart-doc/documents`,
                        icon: <LocalLibraryIcon />,
                        iconInactive: <LocalLibraryOutlinedIcon />,
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
      ...(roles?.some((r) => [ROLES.ADMIN, ROLES.PAGE.STATISTICS].includes(r))
        ? [
            {
              label: t("analyze"),
              items: [
                ...(roles?.some((r) =>
                  [ROLES.ADMIN, ROLES.PAGE.STATISTICS].includes(r)
                )
                  ? [
                      {
                        label: t("statistics"),
                        href: `/${tenant}/statistics`,
                        icon: <AnalyticsIcon />,
                        iconInactive: <AnalyticsOutlinedIcon />,
                      },
                    ]
                  : []),

                ...(roles?.includes(ROLES.ADMIN)
                  ? [
                      {
                        label: t("nlu_inbox"),
                        href: `/${tenant}/nlu-inbox`,
                        icon: <InboxIcon />,
                        iconInactive: <MoveToInboxOutlinedIcon />,
                      },
                    ]
                  : []),
                ...(roles?.includes(ROLES.ADMIN)
                  ? [
                      {
                        label: t("management_statistics"),
                        href: `/${tenant}/management-statistics`,
                        icon: <InsightsIcon />,
                        iconInactive: <InsightsIcon />,
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
      ...(roles?.some((r) =>
        [
          ROLES.ADMIN,
          ROLES.PAGE.DIALOGUE_EDITOR,
          ROLES.PAGE.QNA_EDITOR,
          ROLES.PAGE.KNOWLEDGE_MANAGEMENT,
        ].includes(r)
      )
        ? [
            {
              label: t("build"),
              items: [
                ...(roles?.some((r) =>
                  [ROLES.ADMIN, ROLES.PAGE.DIALOGUE_EDITOR].includes(r)
                )
                  ? [
                      {
                        label: t("dialogue_editor"),
                        href: `/${tenant}/dialogue-editor`,
                        icon: <AccountTreeIcon />,
                        iconInactive: <AccountTreeOutlinedIcon />,
                      },
                    ]
                  : []),

                ...(roles?.some((r) =>
                  [ROLES.ADMIN, ROLES.PAGE.QNA_EDITOR].includes(r)
                )
                  ? [
                      {
                        label: t("answer_editor"),
                        href: `/${tenant}/answer-editor`,
                        icon: <RateReviewIcon />,
                        iconInactive: <RateReviewOutlinedIcon />,
                      },
                    ]
                  : []),
                ...(roles?.some((r) =>
                  [ROLES.ADMIN, ROLES.PAGE.KNOWLEDGE_MANAGEMENT].includes(r)
                )
                  ? [
                      {
                        label: t("knowledge_management"),
                        href: `/${tenant}/knowledge-management`,
                        icon: <LibraryBooksIcon />,
                        iconInactive: <LibraryBooksOutlinedIcon />,
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
      ...((REACT_APP_TENANT === "aar" &&
        roles?.some((r) =>
          [
            ROLES.ADMIN,
            ROLES.PAGE.MAPPING_EDITOR,
            ROLES.PAGE.SETTINGS,
          ].includes(r)
        )) ||
      roles?.some((r) =>
        [ROLES.ADMIN, ROLES.PAGE.MAPPING_EDITOR, ROLES.PAGE.SETTINGS].includes(
          r
        )
      )
        ? [
            {
              label: t("setup"),
              items: [
                ...(REACT_APP_TENANT === "aar" &&
                roles?.some((r) =>
                  [ROLES.ADMIN, ROLES.PAGE.MAPPING_EDITOR].includes(r)
                )
                  ? [
                      {
                        label: t("mapping_editor"),
                        href: `/${tenant}/mapping-editor`,
                        icon: <MapIcon />,
                        iconInactive: <MapOutlinedIcon />,
                      },
                    ]
                  : []),
                ...(roles?.some((r) =>
                  [ROLES.ADMIN, ROLES.PAGE.SETTINGS].includes(r)
                )
                  ? [
                      {
                        label: t("Rules.navbar_title"),
                        href: `/${tenant}/rules/triggers`,
                        icon: <PolylineIcon />,
                        iconInactive: <PolylineOutlinedIcon />,
                      },
                    ]
                  : []),
                ...(roles?.some((r) =>
                  [ROLES.ADMIN, ROLES.PAGE.SETTINGS].includes(r)
                )
                  ? [
                      {
                        label: t("settings"),
                        href: `/${tenant}/settings`,
                        icon: <SettingsIcon />,
                        iconInactive: <SettingsOutlinedIcon />,
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
    ];
  };

  return (
    <Drawer
      sx={{
        "& .MuiDrawer-paper": {
          background: `linear-gradient(0deg, ${theme.navbar.gradientEnd} 0%, ${theme.navbar.gradientStart} 100%)`,
          boxSizing: "border-box",
          overflow: "visible",
        },
      }}
      elevation={3}
      variant="permanent"
      anchor="left"
      open={open}
    >
      <IconButton
        aria-label={open ? "closedrawer" : "opendrawer"}
        role="button"
        sx={{
          position: "absolute",
          background: theme.palette.secondary.light,
          right: 0,
          zIndex: 10,
          top: "78px",
          transform: "translateX(50%)",
          boxShadow:
            "rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 4px 1px",
          height: "14px",
          width: "14px",
          fontSize: "0.9em",
          transition:
            "background-color 100ms linear 0s, color 100ms linear 0s, opacity 350ms cubic-bezier(0.2, 0, 0, 1) 0s",
          "&:hover": {
            background: theme.palette.secondary.light,
          },
        }}
        onClick={handleClose}
      >
        {open ? (
          <ChevronLeftIcon fontSize="inherit" />
        ) : (
          <ChevronRightIcon fontSize="inherit" />
        )}
      </IconButton>
      <DrawerHeader>
        {!WHITELABEL_INSTANCES.includes((tenant as string)?.toLowerCase()) && (
          <Logo thumbnail={open} />
        )}
      </DrawerHeader>
      <Divider />
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          "& .MuiListItemIcon-root": {
            fontSize: 18,
            mr: 1,
          },
          // "& .MuiListItem-root:nth-last-child(2),a:nth-last-child(2)": {
          //   marginTop: "auto",
          // },
        }}
      >
        {tenant &&
          NavLinks().map((group, index) => (
            <Box key={index}>
              {group.label && (
                <Fade
                  timeout={{ enter: 2000, exit: 100 }}
                  in={open}
                  unmountOnExit={true}
                >
                  <ListSubheader
                    sx={{
                      background: "transparent",
                      color: "white",
                      paddingLeft: "10px",
                      height: "36px",
                    }}
                  >
                    {group.label}
                  </ListSubheader>
                </Fade>
              )}
              <Box sx={{ paddingBottom: 2 }}>
                {group.items.map((element) => (
                  <Link href={element.href} key={element.label} passHref>
                    <LinkListItem
                      open={open}
                      label={element.label}
                      icon={
                        isLinkSelected(
                          element.href
                            .replace("/ongoing", "")
                            .replace("/documents", ""),
                          router.asPath,
                          tenant
                        )
                          ? element.icon
                          : element.iconInactive
                      }
                      theme={theme}
                      selected={isLinkSelected(
                        element.href
                          .replace("/ongoing", "")
                          .replace("/documents", ""),
                        router.asPath,
                        tenant
                      )}
                    />
                  </Link>
                ))}
              </Box>
              <Divider />
            </Box>
          ))}
        {roles?.includes(ROLES.ADMIN) && (
          <>
            <LinkListItem
              open={open}
              label={t("message_debugger")}
              icon={<OnlinePredictionIcon />}
              theme={theme}
              onClick={() => {
                props.onClickMessageDebugger();
              }}
            />

            <LinkListItem
              open={open}
              label={t("advanced_setup")}
              icon={<SettingsSuggestIcon />}
              theme={theme}
              onClick={() => {
                props.onClickAdvancedSetup();
              }}
            />
          </>
        )}
        <Box sx={{ mt: "auto" }} />
        {roles?.includes(ROLES.ADMIN) && (
          <Link href={REACT_APP_HELPDOCS_URL} passHref>
            <a
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <LinkListItem
                open={open}
                label={t("Documentation")}
                icon={<HelpOutlineIcon />}
                theme={theme}
              />
            </a>
          </Link>
        )}
        <LinkListItem
          open={open}
          label={t("logout")}
          icon={<LogoutOutlined />}
          theme={theme}
          onClick={() => {
            // @ts-ignore
            keycloak?.logout();
          }}
        />
      </List>
    </Drawer>
  );
};

export default NavSideBar;
