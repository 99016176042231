import { of } from "rxjs";
import { switchMap, mergeMap, map, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import {
  setDialogueGraphData,
  createDialogueGraph,
} from "../actions/dialogues";
import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";
import {
  fetchFilesFulfilled,
  fetchThemeFulfilled,
  uploadFileFulfilled,
  uploadThemeFulfilled,
} from "../actions/files";
import getConfig from "lib/config";
const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST } = publicRuntimeConfig;

export const uploadFile = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.UPLOAD_FILE),
    mergeMap((action) => {
      const body = new FormData();
      body.append("file", action.file);
      body.append("graph_id", action.graph_id);
      body.append("formFieldName", action.formFieldName);
      return ajax
        .post(`${REACT_APP_ACTION_HOST}/themes/file`, body, {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        })
        .pipe(
          map((response) => {
            let file_uri = response?.response?.file_url;
            return uploadFileFulfilled(file_uri, action.formFieldName);
          }),
          catchError((error) => {
            return of(errorMessage(error.message));
          })
        );
    })
  );

export const uploadTheme = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.UPLOAD_THEME_CONFIG),
    mergeMap((action) => {
      return ajax
        .post(`${REACT_APP_ACTION_HOST}/themes/config`, action.payload, {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        })
        .pipe(
          map((response) => {
            let file_uri = response?.response?.file_url;
            return uploadFileFulfilled(file_uri, action.formFieldName);
          }),
          catchError((error) => {
            return of(errorMessage(error.message));
          })
        );
    })
  );

export const fetchTheme = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_THEME_CONFIG),
    mergeMap((action) => {
      return ajax
        .post(`${REACT_APP_ACTION_HOST}/themes/theme`, action.payload, {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        })
        .pipe(
          map((response) => {
            let config = response?.response;
            return fetchThemeFulfilled(config);
          }),
          catchError((error) => {
            if (error.status === 404) {
              return of(fetchThemeFulfilled({}));
            }
            return of(errorMessage(error.message));
          })
        );
    })
  );

export const fetchFiles = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_FILES),
    mergeMap((action) => {
      return ajax.get(`${REACT_APP_ACTION_HOST}/files`, {
        Authorization: action.token,
        Tenant_Realm: state$.value.tenant,
        Type_Origin: "dashboard",
      });
    }),
    map((response) => {
      let file_uri = response?.response?.files;
      return fetchFilesFulfilled(file_uri);
    }),
    catchError((error) => {
      return of(errorMessage(error.message));
    })
  );
